import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Button, ControlGroup,InputGroup, EditableText, Tooltip, Position, TextArea, Hotkey, Hotkeys  } from "@blueprintjs/core";
import { HotkeysTarget } from "@blueprintjs/core/lib/esnext/components/hotkeys/hotkeysTarget";


@withRouter
@translate()
@inject("store")
@HotkeysTarget
@observer
export default class SendForm extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }
    createTalk()
    {
        if( this.props.createTalk ) this.props.createTalk();
    }

    changeRole()
    {
        if( this.props.changeRole ) this.props.changeRole();
    }

    setRole( id )
    {
        if( this.props.changeRole ) this.props.changeRole( id );
    }

    saveFile()
    {
        this.props.store.saveToFile();
    }




    renderHotkeys() 
    {
        return <Hotkeys>
            <Hotkey
                global={true}
                combo="tab"
                label="切换角色"
                allowInInput={true}
                stopPropagation={true}
                preventDefault={true}
                onKeyDown={() => this.changeRole()}
            />
            <Hotkey
                global={true}
                combo="cmd + enter"
                label="添加台词"
                allowInInput={true}
                stopPropagation={true}
                preventDefault={true}
                onKeyDown={() => this.createTalk()}
            />
            <Hotkey
                global={true}
                combo="ctrl + enter"
                label="添加台词"
                allowInInput={true}
                stopPropagation={true}
                preventDefault={true}
                onKeyDown={() => this.createTalk()}
            />
            <Hotkey
                global={true}
                combo="cmd + down"
                label="切换角色为旁白"
                allowInInput={true}
                stopPropagation={true}
                preventDefault={true}
                onKeyDown={() => this.setRole(0)}
            />
            <Hotkey
                global={true}
                combo="ctrl + down"
                label="切换角色为旁白"
                allowInInput={true}
                stopPropagation={true}
                preventDefault={true}
                onKeyDown={() => this.setRole(0)}
            />
            <Hotkey
                global={true}
                combo="cmd + s"
                label="保存"
                allowInInput={true}
                stopPropagation={true}
                preventDefault={true}
                onKeyDown={() => this.saveFile()}
            />
            <Hotkey
                global={true}
                combo="ctrl + s"
                label="保存"
                allowInInput={true}
                stopPropagation={true}
                preventDefault={true}
                onKeyDown={() => this.saveFile()}
            />
        </Hotkeys>;
    }

    render()
    {
        const store = this.props.store;
        // const props = this.props;
        
        return <TextArea 
                    value={store.tosend_text} 
                    onChange={(e)=>store.tosend_text=e.target.value} large={true} 
                    fill={true} 
                    placeholder={"cmd/ctrl+enter提交、tab切换角色、cmd/ctrl+↓切为旁白；---可分割多行内容。\n\n设置多星标✡︎后只在星标角色切换。" + (store.focused_talk_id > 0 ? "当前为追加状态，台词会追加到选中项之后" : "") }
                />;
    }
}