import { observable, action, computed } from "mobx";
import arrayMove from 'array-move';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { blobToBinaryString } from '../util/blob';
import resize from '../util/resize';

const array_chunks = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size));

class AppState
{
    @observable appname = "方糖小剧场·编辑器 H2Editor";
    @observable bookdata = {};
    @observable chapter_filter = "";
    @observable focused_talk_id = 0;
    @observable currentChapterNumber = 1;

    @observable toast_class = "";
    @observable toast_text = "";
    
    @observable tosend_text = "";
    @observable tosend_role_id = 0;
    @observable focused_talk_id = 0;

    @observable show_preview = true;
    @observable preview_talk_id = 0;

    @observable download_pages = [];
    @observable limit = 50;

    @observable editing_role = false;
    local_file_path = false;
    
    @observable show_print = false;
    
    exportBook()
    {
        // let book = {};
        
        // 首先，获取本章的内容
        let talks = this.currentTalks;

        // 然后，从talks中抽取用到的roles
        let roles = [];
        talks.forEach( item =>{
            if( !this.find_by_id( roles , item.role_id ) )
            {
                let _role =  {...this.getRole( item.role_id )};
                _role.note = null;
                _role.isstar = null;
                roles.push( _role );
            }
                
        });

        // 最后补充下基本信息
        let meta = {...this.bookdata.bookinfo};

        // 获取本章名称
        const chapter_name = this.currentChapter.title;
        
        if( chapter_name.trim().length > 0 )
            meta.name += '·' + chapter_name;
        
            const book = { meta , roles , talks };

        const content = JSON.stringify( book );

        FileSaver.saveAs(new Blob([content], {
            type: "text/json;charset=utf-8"
          }), meta.name + '.' + Date.now() + ".h2book");
    }
    
    async downImage()
    {
        let sdiv = document.querySelector("#sctmp");
        if( !sdiv )
        {
            sdiv = document.createElement("div");
        
            sdiv.style.width="440px";
            sdiv.style.padding="20px";
            
            sdiv.setAttribute("id", "sctmp");
            sdiv.classList.add('snap-talk-list');
            document.body.appendChild(sdiv);
        }
        
        if( this.download_pages.length > 0 )
        {
            const page =this.download_pages.shift();
            this.bookdata.talks = page;
            // console.log( "page", JSON.stringify(page) );
            
            window.setTimeout( async ()=>{
                sdiv.innerHTML = document.querySelector(".printbox .talklist-ro .thelist").innerHTML;

                const canvas = await html2canvas(document.querySelector("#sctmp"),{scale:2});
                // canvas.toBlob( ( blob ) =>
                // {
                //     FileSaver( blob , this.bookdata.bookinfo.name + '.' + Date.now() + ".png" );
                // } );
                //const small_base64 = await resize( base64 , 880 , 6000 );
                //await FileSaver( small_base64 , this.bookdata.bookinfo.name + '.' + Date.now() + ".png" );
                const base64 = await canvas.toDataURL();
                await FileSaver( base64 , this.bookdata.bookinfo.name + '.' + Date.now() + ".png" );
                await this.downImage();
            }, 1000 );   
        }
        else
        {
            this.show_print = false;
            this.bookdata.talks = this.saved_talks;
            document.querySelector("#sctmp").remove();
        }
    }
    
    async exportImages()
    {
        this.saved_talks = this.bookdata.talks;
        const current_talks = this.currentTalks;
        this.download_pages = array_chunks( current_talks, this.limit );
        this.show_print = true;
        await this.downImage();
        return ;
        if( pages.length > 0 )
        {
            for( const page of pages )
            {
                console.log( "page" , JSON.stringify(page) );
                this.bookdata.talks = page;
                //this.preview_talk_id = page[page.length-1].id;
                this.previewTo( page[page.length-1].id );
                
                //alert("123");
                // let sdiv;
                // if( !document.querySelector("#sctmp") )
                // {
                //     sdiv = document.createElement("div");
                
                //     sdiv.style.width="440px";
                //     sdiv.style.padding="20px";

                //     sdiv.setAttribute("id", "sctmp");
                //     sdiv.classList.add('snap-talk-list');
                //     document.body.appendChild(sdiv);
                
                // }
                
                // sdiv.innerHTML = document.querySelector(".previewbox .talklist-ro .thelist").innerHTML;

                // const canvas = await html2canvas(document.querySelector("#sctmp"));
                // canvas.toBlob( ( blob ) =>
                // {
                //     FileSaver( blob , this.bookdata.bookinfo.name + '.' + Date.now() + ".png" );
                    
                // } );
            }

            // document.body.removeChild(document.querySelector("#sctmp"));
        }

        console.log( JSON.stringify(this.bookdata.talks) )
        this.bookdata.talks = saved_talks;

        
    }

    @action previewTo( id )
    {
        this.show_preview = true;
        this.preview_talk_id = id;
    }
    
    @action createChapter()
    {
        const newid = parseInt(this.maxid( this.bookdata.chapters ))+1;
        console.log( "in " , newid );
        const chapter = {  "id": newid , "title":"第"+newid+"章" , "note":""  };
        this.bookdata.chapters.push( chapter );
        this.save();
        return true;
    }

    @action
    repeatChapter( id )
    {
        const old_chapter = this.find_by_id( this.bookdata.chapters , id ) ;
        
        let chapter = {...old_chapter};

        if( chapter ) 
        {
            chapter.id = parseInt(this.maxid( this.bookdata.chapters ))+1;
            chapter.title = chapter.title + 'Copy';

            this.bookdata.chapters.push( chapter );

            console.log("repeat chapter");

            const talks = this.bookdata.talks.filter( item => item.chapter_id == id ).sort( ( a , b ) => parseInt(a.order) - parseInt(b.order)   );

            let max_chapter_id = parseInt(this.maxid( this.bookdata.talks ))+1;
            let max_chapter_order = parseInt(this.maxid( this.bookdata.talks , 'order' ))+1;

            talks.forEach( item => 
            {
                let new_item = {...item};
                new_item.id = max_chapter_id++;
                new_item.chapter_id = chapter.id;
                new_item.order = max_chapter_order++;

                console.log( "foreach" ,  new_item , JSON.stringify(item) );

                this.bookdata.talks.push( new_item );
            });

            // this.currentChapterNumber = chapter.id;
            
            // this.save();

        } 
    }
    
    @action
    updateChapterTitle( id , title )
    {
        this.bookdata.chapters.forEach( (item , index) => {
        if( item.id == id ) this.bookdata.chapters[index].title = title;
        } );
        this.save();
        return true;
    }

    @action 
    removeChapter( id )
    {
        this.bookdata.chapters = this.bookdata.chapters.filter( item => item.id != id );

        this.bookdata.talks = this.bookdata.talks.filter( item => item.chapter_id != id );

        this.save();
    }
    
    @action
    removeRole( id )
    {
        this.bookdata.roles = this.bookdata.roles.filter( item => item.id != id );

        this.bookdata.talks = this.bookdata.talks.filter( item => item.role_id != id );

        this.save();
    }
    
    @action
    updateRole( id , name , base64, ismain , note )
    {
        const role = Object.assign( this.getRole( id ) , {  id , name , base64 , ismain , note });

        this.bookdata.roles.forEach( (item , index) => {
        if( item.id == id ) this.bookdata.roles[index] = role;
        } );
        this.save();
        return true;
    }

    @action 
    modifyRole( id )
    {
        
        this.editing_role = this.getRole( id );

    }

    @action
    starRole( id )
    {
        
        this.bookdata.roles.forEach( (item , index) => {
            if( item.id == id )
            {
                if( this.bookdata.roles[index].isstar )
                {
                    this.bookdata.roles[index].isstar = false;
                }
                else
                {
                    this.bookdata.roles[index].isstar = true;
                }
            } ;
        } );
        this.save();
        // console.log( this.getRole( id ) );
        return true;
    }

    @action
    mainRole( id )
    {
        
        this.bookdata.roles.forEach( (item , index) => {
            if( item.id == id )
            {
                if( this.bookdata.roles[index].ismain )
                {
                    this.bookdata.roles[index].ismain = false;
                }
                else
                {
                    this.bookdata.roles[index].ismain = true;
                }
            } ;
        } );
        this.save();
        // console.log( this.getRole( id ) );
        return true;
    }
    
    @action
    setPreview( id )
    {
        this.preview_talk_id= id;
        this.show_preview = ture;
    }
    
    @action
    removeTalk( id )
    {
        this.bookdata.talks = this.bookdata.talks.filter( item => item.id != id );
        this.save();
    }
    
    @action
    updateTalk( id , text )
    {
        this.bookdata.talks.forEach( (item , index) => {
        if( item.id == id ) this.bookdata.talks[index].text = text;
        } );
        this.save();
    }

    @action
    updateTalkOrder( id , order )
    {
        this.bookdata.talks.forEach( (item , index) => {
        if( item.id == id ) this.bookdata.talks[index].order = order;
        } );
        this.save();
    }

    @action
    updateTalkRole( id , role_id )
    {
        this.bookdata.talks.forEach( (item , index) => {
        if( item.id == id )
        {
            this.bookdata.talks[index].role_id = role_id;
            if( role_id == 0 )
                this.bookdata.talks[index].type="aside";
            else
                this.bookdata.talks[index].type="role";

                this.bookdata.talks[index].role_ismain = this.getRole( role_id ).ismain;
        }
        } );
        
        this.save();
    }


    @action
    nextRole( id )
    {
        let roles = this.bookdata.roles.filter( item => item.isstar );

        if( roles.length < 2 )
            roles = this.bookdata.roles;
        
        let ret = roles[0].id;
        roles.forEach( (item , index) => {
        if( item.id == id )
        {
            if( roles[index+1] ) ret = roles[index+1].id;
        }
        } );
        return ret;
    }


    @action
    async openFile( file )
    {
        const zip = await JSZip.loadAsync( file );
        const data = await zip.file("h2content.json").async("string");
        const jsondata = JSON.parse( data );

        if( jsondata )
        {
            this.bookdata = {...jsondata};
            // this.save();
        } 
    }


    async saveToFile()
    {
        const data = this.bookdata;
        const content = JSON.stringify( data );
        
        if( window.require  )
        {
            
            if( !this.local_file_path )
            {
                const {dialog} = window.require('electron').remote;
                const fs = window.require('fs');

                this.local_file_path = await dialog.showSaveDialog( {"filters":[{"name":"小剧场文档","extensions":["h2doc"]}]} );

            }


            if( this.local_file_path )
            {
                // 直接保存本地文件内容
                const fs = window.require('fs');
                //console.log( content );
                fs.writeFile( this.local_file_path, content , (err) => {
                    if(err){
                        alert("An error ocurred creating the file "+ err.message)
                    }
                                
                    this.toast("保存成功");
                });

                window.localStorage.setItem( "last_file" ,  this.local_file_path );
            }
            
            
        }
        else
        {
            //console.log( content );
            // 下载
            FileSaver.saveAs(new Blob([content], {
                type: "text/json;charset=utf-8"
              }), this.bookdata.bookinfo.name + '.' + Date.now() + ".h2doc");
        }
        console.log("Download");
    }

    @computed
    get currentTalks()
    {
        if( !this.bookdata.talks ) return false;
        
        return this.bookdata.talks && this.bookdata.talks.filter( item => item.chapter_id == this.currentChapterNumber ).sort( ( a , b ) => parseInt(a.order) - parseInt(b.order)   );
    }

    @action
    createTalk()
    {
        const type = this.tosend_role_id > 0 ? "role" : "aside";

        
        const role_ismain = this.find_by_id( this.bookdata.roles , this.tosend_role_id  ).ismain;

        const talk = { "id":parseInt(this.maxid( this.bookdata.talks ))+1 , "chapter_id":this.currentChapterNumber  , type ,"role_id":this.tosend_role_id , "role_ismain": role_ismain , "order":parseInt(this.maxid( this.bookdata.talks , "order" ))+1  , "text" : this.tosend_text };

        this.bookdata.talks.push( talk );
        this.save();
    }

    @action
    appendTalk( the_id )
    {
        console.log("append");
        const type = this.tosend_role_id > 0 ? "role" : "aside";
        const role_ismain = this.find_by_id( this.bookdata.roles , this.tosend_role_id  ).ismain;

        const newid = parseInt(this.maxid( this.bookdata.talks ))+1;
        let talk = { "id": newid, "chapter_id":this.currentChapterNumber  , type ,"role_id":this.tosend_role_id , "role_ismain": role_ismain , "order":parseInt(this.maxid( this.bookdata.talks , "order" ))+1  , "text" : this.tosend_text };
        
        // this.bookdata.talks.push( talk );
        let order = 1;
        let new_order = 0;
        let order_array = [];
        this.currentTalks.forEach( ( item ) => {
        
        order_array.push( {"id":item.id,"order":order} );
        // this.updateTalkOrder( item.id , order );
        order++;

        if( item.id == the_id )
        {
            console.log("find id", the_id);
            new_order = order++;
            // order_array.push( {"id":newid,"order":new_order} );
            // order++;
        }
        } );

        if( new_order > 0 )
        {
        // console.log( new_order , order_array );
        order_array.forEach( (item) =>
        {
            this.updateTalkOrder( item.id , item.order );
        } );
        }
        
        talk.order= new_order;
        this.bookdata.talks.push( talk );
        this.save();
    }
    
    @action
    set_tosend_role( id )
    {
        this.tosend_role_id = id;
        this.toast("角色已切换");
    }

    @computed
    get tosend_role()
    {
        if( !this.bookdata.roles ) return false;
        return this.find_by_id(this.bookdata.roles,this.tosend_role_id);
    }

    getRole( id )
    {
        return this.find_by_id(this.bookdata.roles,id);
    }

    find_by_id( array , id )
    {
        return array.filter( item => item.id == id )[0];
    }

    @action
    createRole( name , base64, ismain , note )
    {
        
        const role = {  "id":parseInt(this.maxid( this.bookdata.roles ))+1 , name , base64 , ismain , note , "isstar":false };
        this.bookdata.roles.unshift( role );
        this.save();
        return true;
    }

    

    maxid( array , field = 'id' )
    {
        if( !Array.isArray( array ) ) return 1;
        
        let max = 0;
        array.forEach( item => { if( item[field] > max ) max = item[field] } );
        return max;
    }
    
    toast( text )
    {
      this.toast_text = text;
      this.toast_class = 'show';
      setTimeout(() => {
          this.toast_class = '';    
      }, 4000);
    }

    
    @action
    saveBookInfo( text , field , tofile = true )
    {
        this.bookdata.bookinfo[field] = text;
        if( tofile ) this.save();
    }
    
    @action
    saveChapterNote( text , tofile = true )
    {
        this.bookdata.chapters.forEach( (item , key) => {
        if( item.id == this.currentChapterNumber ) this.bookdata.chapters[key].notes =text;
        }   );
        if( tofile ) this.save();
    }
    
    @action
    reOrder( oldIndex , newIndex )
    {
        // console.log( oldIndex , newIndex );
        this.bookdata.chapters = arrayMove(this.bookdata.chapters, oldIndex, newIndex);
        this.save();
    }
    
    save()
    {
        // save to localstorage
        // console.log( JSON.stringify(this.bookdata) );
        window.localStorage.setItem( "h2editor_data" , JSON.stringify(this.bookdata) );
    }

    load()
    {
        
        const last_file = window.localStorage.getItem( "last_file");

        if( last_file && window.require )
        {
            const fs = window.require('fs');
            if( fs.existsSync( last_file ) )
            {
                const data = fs.readFileSync( last_file , 'utf8' );
                const jsondata = JSON.parse( data );
                    // console.log( " jsondata " , jsondata );

                if( jsondata )
                {
                    this.bookdata = {...jsondata};
                    this.local_file_path = last_file;
                }
                
                return true;
            }
            else
                console.log( "last_file not exists" , last_file );
        }else
            console.log( "last_file undefined" , last_file );

        const bookdata = JSON.parse( window.localStorage.getItem( "h2editor_data") );

        if( bookdata ) this.bookdata =  bookdata;
        else this.loadBookData();

        if( window.localStorage.getItem( "h2editor_preview") === null )         this.show_preview = true;
        else
            this.show_preview = parseInt( window.localStorage.getItem( "h2editor_preview") ) == 1 ;
        
        
        

        // console.log( "pv" , this.show_preview )
    }

    @action
    togglePreview()
    {
        this.show_preview = !this.show_preview;
        window.localStorage.setItem( "h2editor_preview",this.show_preview ? 1 : 0 );
    }

    @action setBookData( jsondata )
    {
        // 同时支持 h2doc 和 h2book 格式的打开
        // 拥有 chapters 和 bookinfo 的是 h2doc
        if( jsondata.chapters && jsondata.bookinfo )
        {
            this.bookdata = {...jsondata};
        }
        else
        {
            // 这里应该是 h2book 格式，特点是没有 chpaters 和 bookinfo( 改为 meta )
            const chapters = [{"id":1,"title":"第1章" , "content":[] , "notes":""}];


            jsondata.talks.forEach( item => item.chapter_id = 1 );
            
            this.bookdata = {"bookinfo":jsondata.meta , "roles":jsondata.roles , "talks":jsondata.talks , chapters };
        }
    }

    @action
    loadBookData()
    {
        const bookinfo = { "author":"Easy" , "name":"未命名小剧场·第一卷" , "detail":"有趣的对话体小说" , "link":"https://weibo.com/Easy" };

        const chapters = [
        {"id":1,"title":"第1章" , "content":[] , "notes":""},
        {"id":2,"title":"第2章" , "content":[] , "notes":""},
        {"id":3,"title":"第3章" , "content":[] , "notes":""},
        {"id":4,"title":"第4章" , "content":[] , "notes":""},
        ];

        const roles = [
        {"id":0,"name":"旁白","base64":"","ismain":false}
        ];

        this.bookdata.bookinfo = bookinfo;
        this.bookdata.chapters = chapters;
        this.bookdata.roles = roles;
        this.bookdata.talks = [];

    }

    @computed get filteredChapters()
    {
        if( this.chapter_filter.length < 1 ) return this.bookdata.chapters;
        else
        return this.bookdata.chapters.filter( item => item.title.indexOf( this.chapter_filter ) >= 0 );
    }

    @computed get currentChapter()
    {
      
      return this.bookdata.chapters && this.bookdata.chapters.filter( item => item.id == this.currentChapterNumber )[0];
    }

    set currentChapter( id )
    {
      this.currentChapterNumber = id ;
      console.log( JSON.stringify(this.currentChapter));
    }


}

export default new AppState();