import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import SplitPane from 'react-split-pane';
import DocumentTitle from 'react-document-title';
import { Button, ControlGroup,InputGroup, EditableText, Tooltip, Position, TextArea, Hotkey, Hotkeys  } from "@blueprintjs/core";
import { HotkeysTarget } from "@blueprintjs/core/lib/esnext/components/hotkeys/hotkeysTarget";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import ChapterItem from '../component/ChapterItem'; 
import BookInfoBox from '../component/BookInfoBox'; 
import AvatarUploadButton from '../component/AvatarUploadButton'; 
import AvatarCircle from '../component/AvatarCircle'; 
import AvatarSquare from '../component/AvatarSquare'; 
import TalkListEditable from '../component/TalkListEditable'; 
import TalkList from '../component/TalkList';
import TalkListPrint from '../component/TalkListPrint'; 

import SendForm from '../component/SendForm';
import OpenFileButton from '../component/OpenFileButton'; 
import TrafficLight from '../component/TrafficLight'; 
import DragDiv from '../component/DragDiv'; 
import LocalFileButton from '../component/LocalFileButton'; 




@withRouter
@translate()
@inject("store")
@observer
export default class Editor extends Component
{
    constructor(props) 
    {
        super(props);
        this.list_ref = React.createRef();
        this.end_ref = React.createRef();
    }

    componentDidMount()
    {
       this.props.store.load(); 
       // console.log( "loaded" + this.props.store.show_preview );
    }
   

    createTalk()
    {
        const store = this.props.store;
        
        store.tosend_text = store.tosend_text.trim();
        if( store.tosend_text.length < 1 ) return false;


        store.tosend_text.split("\n---\n").forEach( item =>
        {
            if( item.trim().length > 0 )
            {
                store.tosend_text = item.trim();

                if( store.focused_talk_id > 0  )
                {
                    store.appendTalk( store.focused_talk_id );
                    store.tosend_text = "";
                }
                else
                {
                    store.createTalk();
                    store.tosend_text = "";
                    this.toBottom();
                }
            }
        })       
    }

    toBottom = () =>
    {
        this.end_ref.current.scrollIntoView( true );
    }

    roleModify( id )
    {
        const store = this.props.store;
        if( id < 1 )
        {
            store.toast("旁白角色不可修改");
            return false;
        }
        store.modifyRole( id );
        // console.log( store.editing_role );
    }

    roleRemove( id )
    {
        const store = this.props.store;
        if( id < 1 )
        {
            store.toast("旁白角色不可删除");
            return false;
        }

        if( !window.confirm("角色删除后，所有角色的台词都将同步删除，请务必确认后继续") ) return  false;

        store.removeRole( id );
    }

    clean()
    {
        if( !window.confirm("未保存的内容将全部丢失，请务必确认后继续") ) return  false;

        const store = this.props.store;
        store.loadBookData();
    }

    roleStar( id )
    {
        const store = this.props.store;
        if( id < 0 )
        {
            store.toast("错误的角色ID");
            return false;
        }
        store.starRole( id );
        // console.log( store.editing_role );
    }

    roleMain( id )
    {
        const store = this.props.store;
        if( id < 1 )
        {
            store.toast("旁白角色不可设为主角");
            return false;
        }
        store.mainRole( id );
        // console.log( store.editing_role );
    }

    createChapter()
    {
        const store = this.props.store;
        store.createChapter();
        window.location.reload();
    }

    changeRole( id = -1 )
    {
        // console.log( "change" , id );
        const store = this.props.store;
        
        if( id < 0 )
            store.set_tosend_role( store.nextRole( store.tosend_role_id ) );
        else
            store.set_tosend_role( id );   
    }

    saveToFile()
    {
        const store = this.props.store;
        store.saveToFile();
    }

    

    render()
    {
        const store = this.props.store;

        const SortableItem = SortableElement(({value}) =><ChapterItem data={value} key={value.id} />);

        
        const SortableList = SortableContainer(({items}) => {
            return (
            <ul ref={this.list_ref}>
                {items.map((item , index ) => (
                <SortableItem key={item.id}  index={index} value={item} />
                ))}
            </ul>
            );
        });
        const side_plane = <div className="side">
            <div className="logo"><img src="./image/h2editor.logo.png" alt="logo" className="logo-image" onClick={()=>store.load()}/></div>
            <div className="chapter-box">
                <div className="search">
                <ControlGroup fill={true} vertical={false}>
                    <InputGroup className="gray" leftIcon="filter" placeholder="章节名称关键字..." onChange={(e)=>store.chapter_filter=e.target.value} value={store.chapter_filter}/>
                    <Button icon="small-plus" alt="" onClick={()=>this.createChapter()} />
                </ControlGroup>
                </div>
                <div className="list">
                   

                    { store.filteredChapters && 
                    <SortableList 
                        items={store.filteredChapters} 
                        useDragHandle 
                        onSortEnd={( {oldIndex, newIndex} ) => store.reOrder( oldIndex , newIndex )}
                        helperContainer={()=>this.list_ref.current}
                    /> } 
                    
                </div>
            </div>
            <div className="chapter-intro">
            <div className="introbox">
            <EditableText 
            onConfirm={(text)=>store.saveChapterNote( text )}
            onChange={(text)=>store.saveChapterNote( text , false )}
            value={store.currentChapter && store.currentChapter.notes}
            multiline={true} 
            minLines={3}
            placeholder="本章笔记[仅作者可见]"
            />
            </div>
            </div>
        </div>;

        const story_plane = <div className="story">
        <div className="editbox">
            <div className="talklist"><TalkListEditable refs={this.end_ref}/></div>
            <div className="talkform">
                <div className="left">{store.tosend_role && <div className="rolecard">
                    <AvatarSquare data={store.tosend_role} className="shadow s64" onClick={()=>this.changeRole()}/>
                    <div className="top10">{store.tosend_role.note||store.tosend_role.name}</div>
                </div> }</div>
                <div className="right">
                    
                    
                    <div className="textarea">
                    <SendForm 
                        changeRole={(id)=>this.changeRole(id)}  
                        createTalk={()=>this.createTalk()}  
                    />
                    </div>
                    <div className="actions">
                        <Button text={ store.focused_talk_id > 0 ? "追加到选中项后":"添加" } large={true} onClick={()=>this.createTalk()}/>
                    </div>
                    
                </div>
            </div>
        </div>
        { store.show_print ? <div className="printbox">
        <TalkListPrint roles={store.bookdata.roles} talks={store.currentTalks} />
        </div> : store.show_preview && <div className="previewbox">
            {store.currentTalks && <TalkList talks={store.currentTalks} roles={store.bookdata.roles} current_talk_id={store.preview_talk_id} />  }
        </div> }
        
        </div>;

        const resouce_plane = <div className="resource">
        <div className="bookbox"><BookInfoBox/></div>
            <div className="avatarlist">
            { store.bookdata.roles && store.bookdata.roles.map( item => 
                    <AvatarCircle 
                    onUpdate={()=>this.roleModify( item.id )}  key={item.id}  onRemove={()=>this.roleRemove( item.id )} 
                    onStar={()=>this.roleStar( item.id )}
                    onMain={()=>this.roleMain( item.id )}
                    data={item} onClick={()=>store.set_tosend_role(item.id)} /> ) }    
            </div>
            
            <div className="toolbar">
            <Tooltip content="新增角色" position={Position.TOP}><AvatarUploadButton data={store.send_role} editing_role={store.editing_role} onFinished={()=>store.editing_role=false} /></Tooltip>

            <Tooltip content="保存文档" position={Position.TOP}><Button icon="floppy-disk" minimal={true} onClick={()=>this.saveToFile()} /></Tooltip>

            <Tooltip content="打开文档" position={Position.TOP}>
            { window.require ? <LocalFileButton/> : <OpenFileButton/> }   
            </Tooltip> 

            <Tooltip content="清空当前文档内容" position={Position.TOP}><Button icon="clean" minimal={true} onClick={()=>this.clean()} /></Tooltip> 

            <Tooltip content="开关预览面板" position={Position.TOP}><Button icon="panel-stats" minimal={true} onClick={()=>store.togglePreview()} /></Tooltip> 
            
            <Tooltip content="生成长图文件" position={Position.TOP}><Button icon="media" minimal={true} onClick={()=>store.exportImages()} /></Tooltip> 

            <Tooltip content="生成本章的.h2book文件" position={Position.TOP}><Button icon="manual" minimal={true} onClick={()=>store.exportBook()} /></Tooltip>    
            </div> 
        </div>
        
        const main = <div className="h2editor-page">
        <SplitPane split="vertical" minSize={160} defaultSize={ parseInt(localStorage.getItem('splitPos-1'), 10) || 200 }
               onChange={ size => localStorage.setItem('splitPos-1', size) }
        >
            {side_plane}
            
            <SplitPane split="vertical" minSize={400} defaultSize={ parseInt(localStorage.getItem('splitPos-2'), 10) || 800 }
            onChange={ size => localStorage.setItem('splitPos-2', size) } >
            
            {story_plane}
                
            {resouce_plane}
                
            </SplitPane>
        </SplitPane>
        </div>;
        return <DocumentTitle title={this.props.store.appname}><>{main}
        <TrafficLight/>
        <DragDiv/>
        </></DocumentTitle>;
    }
}