import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Menu, MenuItem,MenuDivider, Position, Tooltip } from "@blueprintjs/core";
import { ContextMenuTarget } from "@blueprintjs/core/lib/esnext/components/context-menu/contextMenuTarget";

@withRouter
@translate()
@inject("store")
@observer
@ContextMenuTarget
export default class AvatarCircle extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }
    update_role = ( id ) =>
    {
        if( this.props.onUpdate ) this.props.onUpdate();

    }

    remove_role = ( id ) =>
    {
        if( this.props.onRemove ) this.props.onRemove();
    }

    star_role = ( id ) =>
    {
        if( this.props.onStar ) this.props.onStar();
    }

    main_role = ( id ) =>
    {
        if( this.props.onMain ) this.props.onMain();
    }

    renderContextMenu() 
    {
        // return a single element, or nothing to use default browser behavior
        const star_text = this.props.data.isstar ? "取消星标" : "设为星标";
        const main_text = this.props.data.ismain ? "取消主角" : "设为主角";
        
        return (
            <Menu>
                <MenuItem onClick={()=>this.star_role( this.props.data.id )} text={star_text} />
                { this.props.data.id != 0 && <>
                <MenuItem onClick={()=>this.main_role( this.props.data.id )} text={main_text} />

                <MenuItem onClick={()=>this.update_role( this.props.data.id )} text="修改角色" />

                <MenuDivider/>
                
                <MenuItem onClick={()=>this.remove_role( this.props.data.id )} text="删除角色" />

                </> }
                
            </Menu>
        );
    }

    render()
    {
        const store = this.props.store;
        const props = this.props;
        const main_class = props.data.ismain ? " main " : "";
        const star_class = props.data.isstar ? " star " : "";

        const url = props.data.base64 || "./image/h2editor.logo.png"; 
        const name = props.data.note ||  props.data.name;
        
        return <div className={"avatar-item" + main_class + star_class } ><Tooltip content={name} position={Position.TOP}><img src={url} alt={name} onClick={(e)=>props.onClick(e)}/></Tooltip>
        
        </div>;
    }
}