import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import TalkItem from '../component/TalkItem'; 

@withRouter
@translate()
@inject("store")
@observer
export default class TalkListPrint extends Component
{
    constructor(props) 
    {
        super(props);
        this.end_ref = React.createRef();
    }

    render()
    {
        const props = this.props;
        
        
        return  props.talks  ?
        <div className="talklist-ro" >
            <div className="thelist">
            { props.talks.length > 0 
            && 
            <>
            {props.talks.map( item => <TalkItem roles={props.roles} key={item.id} data={item} /> ) }
            </>
            }

            <div ref={this.end_ref} style={{"marginTop":"100px"}}></div>
            

            </div>
            <div className="touchpad" >
               ...
            </div>
            
        </div> : null ;
    }
}