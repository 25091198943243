import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Button, FormGroup,InputGroup, Switch, Overlay  } from "@blueprintjs/core";
import Files from "react-butterfiles";
import ImageCropper from '../util/ImageCropper';
import resize from '../util/resize';

@withRouter
@translate()
@inject("store")
@observer
export default class AvatarUploadButton extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    componentDidMount()
    {
        const props = this.props;
        if( props.editing_role )
        {
            this.setRole( role );
        }
    }

    setRole( role )
    {
        this.setState(
        {
            "isOverLay":true,
            "isMain":role.ismain,
            "uid":role.id,
            "uname":role.name,
            "unote":role.note,
            "ufiles":[{"src":{"base64":role.base64}}],
            "uerrors":[],
            "ucropper":false
        }
        );
    }

    componentDidUpdate(prevProps)
    {
        if (this.props.editing_role !== prevProps.editing_role) 
        {
            if( this.props.editing_role )
                this.setRole( this.props.editing_role );
            // console.log( "now id" , this.props.current_talk_id );
        }
    }

    state = {
        "isOverLay":false,
        "isMain":false,
        "uid":0,
        "uname":"",
        "unote":"",
        "ufiles":[],
        "uerrors":[],
        "ucropper":false
    };

    set( name, value )
    {
        let o = this.state;
        o[name] = value;
        this.setState( o );
    }

    handleUpload = ( files ) =>
    {
        this.setState( {
            "ufiles":files,
            "uerrors":[],
            "ucropper":true
        } );
    }

    async createRole()
    {
        const store = this.props.store;
        const state = this.state;

        if( state.uname.length < 1 )
        {
            store.toast("角色姓名不能为空");
            return false;
        } 

        if( state.ufiles && state.ufiles[0] && state.ufiles[0].src && state.ufiles[0].src.base64 )
        {
            const base64 = await resize( state.ufiles[0].src.base64 , 256 , 256 );

            let ret = false;

            if( state.uid > 0 )
            {
                ret = store.updateRole( state.uid, state.uname , base64 , state.isMain , state.unote );
            }
            else
            {
                ret = store.createRole( state.uname , base64 , state.isMain , state.unote );
            }

            if( ret )
            {
                this.setState( {
                    "isOverLay":false,
                    "isMain":false,
                    "uid":0,
                    "uname":"",
                    "unote":"",
                    "ufiles":[],
                    "uerrors":[],
                    "ucropper":false
                });

                if( this.props.onFinished ) this.props.onFinished();
            }
            else
            {
                store.toast("创建角色错误");
            }
        }
        else
        {
            store.toast("请为角色上传头像");
            return false;
        } 
    }

    cancel()
    {
        this.set( "isOverLay",false);
        if( this.props.onFinished ) this.props.onFinished();
    }

    setErrors(e)
    {
        console.log(e);
    }
    
    render()
    {
        const store = this.props.store;
        const props = this.props;
        const state = this.state;

        const style = {
            width: 180,
            height: 180,
            border: "2px lightgray dashed"
        };
        
        return <><div className={props.className} onClick={()=>this.set( 'isOverLay' , true)}><Button icon="new-person" minimal={true} /></div>
        <Overlay isOpen={state.isOverLay}  >
            <div className="avatar-overbox">
                <div className="center-box">
                <FormGroup
                    helperText="如：可卡"
                    label="角色姓名"
                    labelInfo="在阅读环境显示"
                >
                    <InputGroup id="text-input" placeholder="必填项" value={state.uname} onChange={(e)=>this.set('uname',e.target.value)} />
                </FormGroup>
                <FormGroup
                    helperText="如：可卡·笑，不填写将显示姓名"
                    label="角色备注"
                    labelInfo="在编辑环境显示"
                >
                    <InputGroup id="text-input" placeholder="选填项" value={state.unote} onChange={(e)=>this.set('unote',e.target.value)} />
                </FormGroup>
                <div className="file-row">
                <Files
                    accept={["image/jpg", "image/jpeg", "image/png"]}
                    convertToBase64={true}
                    maxSize="30mb" multipleMaxSize="30mb"
                    onSuccess={(e)=>this.handleUpload(e)}
                    onError={(e)=>this.setErrors(e)}
                >
                    {({ browseFiles, getDropZoneProps }) => (
                        <>
                            <label>点击或拖拽头像到下框</label>
                            {state.ucropper ? (
                                <div style={style}>
                                    <ImageCropper 
                                        aspectRatio={1}
                                        minContainerWidth={100}
                                        maxContainerWidth={100}
                                        
                                        minContainerHeight={100}
                                        maxContainerHeight={100}
                                    >

                                        {({ getImgProps, getDataURL }) => (
                                            <div>
                                                <img
                                                    {...getImgProps({
                                                        src: state.ufiles[0].src.base64,
                                                        style: {
                                                            maxWidth: "100%"
                                                        }
                                                    })}
                                                />
                                                <Button small={true} minimal={true}
                                                    onClick={() => {
                                                        let thefiles = state.ufiles;
                                                        thefiles[0].src.base64 =getDataURL();
                                                        this.set( 'ufiles' , thefiles );
                                                        this.set( 'ucropper' , false);
                                                    }}
                                                >
                                                    保存
                                                </Button>
                                            </div>
                                        )}
                                    </ImageCropper>
                                </div>
                            ) : (
                                <div
                                    onClick={browseFiles}
                                    {...getDropZoneProps({
                                        style: { ...style, cursor: "pointer" }
                                    })}
                                >
                                    {state.ufiles[0] && (
                                        <img
                                            style={{ width: "100%" }}
                                            src={state.ufiles[0].src.base64}
                                        />
                                    )}
                                    {state.uerrors.length > 0 && (
                                        <div>An error occured.</div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </Files> 
                
                
                </div>
                
                <FormGroup
                    helperText="主角头像会出现在左侧"
                    label="角色类型"
                >
                    <Switch checked={state.isMain} label="是否主角" onChange={(e)=>this.set( "isMain" , e.target.checked)} />
                </FormGroup>
                <div className="std-hr" />
                <FormGroup
                >
                    <div className="s2row">
                        <Button large={true} minimal={true} onClick={()=>this.cancel()}>取消</Button>
                        <Button large={true} onClick={()=>this.createRole()}>保存</Button>
                    </div>
                    
                    
                </FormGroup>
                
                </div>
            </div>
        
        </Overlay>
        </>;
    }
}