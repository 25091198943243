(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
    typeof define === 'function' && define.amd ? define(['exports'], factory) :
    (factory((global.blobUtil = {})));
}(this, (function (exports) { 'use strict';

        function TheArrayBufferToString(buffer) {
            return TheBinaryToString(String.fromCharCode.apply(null, Array.prototype.slice.apply(new Uint8Array(buffer))));
        }

        function TheBinaryToString(binary) {
            var error;

            try {
                return decodeURIComponent(escape(binary));
            } catch (_error) {
                error = _error;
                if (error instanceof URIError) {
                    return binary;
                } else {
                    throw error;
                }
            }
        }

        function blobToBinaryString2(blob) {
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                var hasBinaryString = typeof reader.readAsBinaryString === 'function';
                reader.onloadend = function () {
                    var result = reader.result || '';
                    if (hasBinaryString) {
                        return resolve(result);
                    }
                    resolve(TheArrayBufferToString(result));
                };
                reader.onerror = reject;
                if (hasBinaryString) {
                    reader.readAsBinaryString(blob);
                }
                else {
                    reader.readAsArrayBuffer(blob);
                }
            });
        }

        function blobToBinaryString( blob )
        {
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onloadend = function () {
                    var result = reader.result || '';
                    resolve(DataURLToString(result));
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }

        function DataURLToString( data )
        {
            const newdata = data.substring( String("data:application/zip;base64,").length );
            return window.atob(newdata);
        }

    exports.blobToBinaryString = blobToBinaryString;
    

    Object.defineProperty(exports, '__esModule', { value: true });

})));

