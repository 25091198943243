import React, { Component } from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Login from './screen/Login';
import Editor from './screen/Editor'; 
import ToastDiv from './component/ToastDiv'; 

class App extends Component {
  render() {
    return (
      <><Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/" component={Editor} />
        </Switch>
      </Router><ToastDiv/></>  
    );
  }
}

export default App;
