import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import store from '../store/AppState';
import { translate } from 'react-i18next';

@translate()
@observer
export default class ToastDiv extends Component
{
    render()
    {
        const info = store.toast_text;
        const class_name = store.toast_class;
        // if( info.length < 1 ) return null;

        return <div className={"toast "+class_name}>{info}</div>;
    }
}