import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Icon } from "@blueprintjs/core";
import './TrafficLight.scss';

@withRouter
@translate()
@inject("store")
@observer
export default class TrafficLight extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }
    close()
    {
        this.props.store.saveToFile();

        if( !window.require ) return false;
        const remote = window.require('electron').remote;
        var thewin = remote.getCurrentWindow();
        this.props.store.toast( "正在保存文件" );
        setTimeout(() => {
            thewin.close();
        }, 1000);
        
    }

    render()
    {
        const store = this.props.store;
        // const props = this.props;

        const show = window.require ? <div className="traffic-light">
        <div className="close" onClick={()=>this.close()}><Icon icon="small-cross" iconSize={10}/></div></div> : null;
        
        return show;
    }
}