import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import TalkItemEditable from '../component/TalkItemEditable'; 

@withRouter
@translate()
@inject("store")
@observer
export default class TalkListEditable extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    render()
    {
        const store = this.props.store;
        const props = this.props;
        
        return <div className="talklist-edt" >
        { store.currentTalks && store.currentTalks.map( item => <TalkItemEditable key={item.id} data={item}  /> ) }
        <div ref={props.refs} style={{"marginTop":"100px"}}></div>
        </div>;
    }
}