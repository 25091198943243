import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Icon, EditableText  } from "@blueprintjs/core";

@withRouter
@translate()
@inject("store")
@observer
export default class BookInfoBox extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }
    
    render()
    {
        const store = this.props.store;
        
        return <div className="bookinfo-box">

        <div className="editline">
            
            <EditableText 
            onConfirm={(text)=>store.saveBookInfo( text , 'name' )}
            onChange={(text)=>store.saveBookInfo( text , 'name'  , false )}
            value={store.bookdata.bookinfo && store.bookdata.bookinfo.name}
            placeholder="氢剧名称"
            className=" title"
            />
        </div>

        <div className="editline">
            <Icon icon="user"/>
            <EditableText 
            onConfirm={(text)=>store.saveBookInfo( text , 'author' )}
            onChange={(text)=>store.saveBookInfo( text , 'author' , false)}
            value={store.bookdata.bookinfo && store.bookdata.bookinfo.author}
            placeholder="作者"
            className="author"
            />
        </div>
        
        <div className="editline">
            <Icon icon="link"/>
            <EditableText 
            onConfirm={(text)=>store.saveBookInfo( text , 'link' )}
            onChange={(text)=>store.saveBookInfo( text , 'link'  , false )}
            value={store.bookdata.bookinfo && store.bookdata.bookinfo.link}
            placeholder="来源链接"
            className=" link"
            />
        </div>
        
        <div className="editline">
            <EditableText 
            onConfirm={(text)=>store.saveBookInfo( text , 'detail' )}
            onChange={(text)=>store.saveBookInfo( text , 'detail' , false )}
            value={store.bookdata.bookinfo && store.bookdata.bookinfo.detail}
            placeholder="介绍"
            className="editline detail"
            multiline={true} 
            minLines={3}
            />
        </div>

        </div>;
    }
}