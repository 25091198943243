import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Button  } from "@blueprintjs/core";

@withRouter
@translate()
@inject("store")
@observer
export default class LocalFileButton extends Component
{
    constructor(props) 
    {
        super(props);

    }
    
    // componentDidMount()
    // {
    //    // 
    // }
    openFile()
    {
        const {dialog} = window.require('electron').remote;
        const fs = window.require('fs');
        const filters = [{"name":"小剧场文档","extensions":["h2doc","h2book"]}];

        dialog.showOpenDialog( {filters} , (fileNames) => {
            // fileNames is an array that contains all the selected
            if(fileNames === undefined){
                console.log("No file selected");
                return;
            }

            console.log( fileNames );
        
            // this.props.store.openFile( fileNames );
            fs.readFile(fileNames[0], (err, data) => 
            {
                if(err){
                    alert("An error ocurred reading the file :" + err.message);
                    return;
                }

                const jsondata = JSON.parse( data );
                // console.log( " jsondata " , jsondata );

                if( jsondata )
                {
                    this.props.store.setBookData( jsondata );  

                    if( jsondata.chapters && jsondata.bookinfo )
                        this.props.store.local_file_path = fileNames[0];
                    else
                        this.props.store.local_file_path = false;     
                } 
            });
        } );
    }

    render()
    {
        const store = this.props.store;
        // const props = this.props;
        
        return <div><Button icon="document-open" minimal={true} onClick={()=>this.openFile()}  /></div>;
    }
}