import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import './DragDiv.scss';
@withRouter
@translate()
@inject("store")
@observer
export default class DragDiv extends Component
{
    // constructor(props) 
    // {
    //     super(props);
    // }
    
    // componentDidMount()
    // {
    //    // 
    // }

    render()
    {
        const store = this.props.store;
        // const props = this.props;
        
        return <div className="drag-div"></div>;
    }
}