import React, { Component } from 'react';
import { observer , inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { SortableHandle } from 'react-sortable-hoc';
import { Icon, EditableText,Menu, MenuItem,MenuDivider } from "@blueprintjs/core";
import { ContextMenuTarget } from "@blueprintjs/core/lib/esnext/components/context-menu/contextMenuTarget";

@withRouter
//@translate()
@inject("store")
@observer
@ContextMenuTarget
export default class ChapterItem extends Component
{
    updateTitle( id , new_title )
    {
        const store = this.props.store;
        store.updateChapterTitle( id , new_title );
    }

    removeChapter( id )
    {
        if( !window.confirm("章节删除后，下属的内容都将同步删除，请务必确认后继续") ) return  false;
        const store = this.props.store;
        store.removeChapter( id );
    }

    repeatChapter( id )
    {
        const store = this.props.store;
        store.repeatChapter( id );
    }

    renderContextMenu()
    {
        return (
            <Menu>
                <MenuItem onClick={()=>this.repeatChapter( this.props.data.id )} text="复印此章" />
                <MenuDivider />
                <MenuItem onClick={()=>this.removeChapter( this.props.data.id )} text="删除此章" />
            </Menu>
        );
    }
    
    render()
    {
        const store = this.props.store;
        const props = this.props;

        const DragHandle = SortableHandle(() => <span className="handle"><Icon  icon="move" iconSize={14} /></span>);

        return <li 
                onClick={()=>
                {
                    // console.log( "in" , props.data.id  );
                    store.currentChapter = props.data.id ;
                    store.focused_talk_id = 0;
                }} 
                className={"chapter-item "+(store.currentChapterNumber == props.data.id ? " active " : " ")}
                >
                {/* {props.data.title} */}
                <EditableText 
                    onConfirm={(text)=>this.updateTitle( props.data.id , text )}
                    // onChange={(text)=>store.saveBookInfo( text , 'link'  , false )}
                    defaultValue={props.data.title}
                    placeholder="不能为空"
                    className="chapter_edit"
                    />
                 <DragHandle/>
                </li>;
    }
}